// import logo from "./logo.svg";
//import "./App.css";
import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Mainpart from "./Mainpart";
import db from "./firebase";
//import firebase from "firebase/app";
//import { serverTimestamp } from "firebase/firestore";

function App() {
  const [serviceDatas, setServiceDatas] = useState([]);
  const [sortBy, setSortBy] = useState("osszes");
  const [valueService, setValueService] = useState("");

  /* useEffect(() => {
    const data = db.collection("serviceDatas")
      //.doc("BNrtegSoF8l4lWaJGij5")
      //.collection("sellerDatas")
      .onSnapshot((snapshot) => {
        setServiceDatas(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        //console.log(serviceDatas);
      });
  }, []);*/

  useEffect(() => {
    db.collection("serviceDatas")
      //.doc("BNrtegSoF8l4lWaJGij5")
      //.collection("sellerDatas")
      .onSnapshot((snapshot) => {
        setServiceDatas(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        console.log("LOAD");
      });
  }, []);

  //let sortedItems1;
  let sortedItems;

  // if (serviceDatas) console.log(serviceDatas.data.createAt);

  // console.log("MOST" + serviceDatas);

  if (sortBy === "osszes")
    sortedItems = serviceDatas.sort(
      (a, b) => Number(b.data.createAt) - Number(a.data.createAt)
    );
  /*const sortedItems1 = serviceDatas.reduce(
      (a, b) =>
        b.data.creatAt > a.data.createAt ? b.data.createAt : a.data.createAt,
      serviceDatas[0]
    )*/

  /*let sortedItems1 = serviceDatas
      .filter((serviceDatas2) => serviceDatas2.data.service === "viz")
      .reduce(
        (a, b) =>
          Number(b.data.position) > Number(a.data.position)
            ? Number(b.data.position)
            : Number(a.data.position),
        serviceDatas[0]
      );*/

  // sortedItems = sortedItems1.concat(sortedItems2);

  //console.log(sortedItems);

  /*sortedItems = sortedItems1.sort(
    (a, b) => Number(a.data.position) - Number(b.data.position)
  );*/
  /*
  console.log(sortedItems);
  console.log(serviceDatas);*/
  if (sortBy === "gaz")
    sortedItems = serviceDatas
      .filter((serviceDatas) => serviceDatas.data.service === "gaz")
      .sort((a, b) => Number(b.data.createAt) - Number(a.data.createAt));

  if (sortBy === "viz")
    sortedItems = serviceDatas
      .filter((serviceDatas) => serviceDatas.data.service === "viz")
      .sort((a, b) => Number(b.data.createAt) - Number(a.data.createAt));

  if (sortBy === "aram")
    sortedItems = serviceDatas
      .filter((serviceDatas) => serviceDatas.data.service === "aram")
      .sort((a, b) => Number(b.data.createAt) - Number(a.data.createAt));
  /*
  console.log(sortedItems);*/
  function addData() {
    //const d = Date();
    //e.preventDefault();
    //db.collection("customersData").doc(customerName).collection("orders").add({
    //console.log(sortedItems[0].data.position);
    if (valueService >= sortedItems[0].data.position) {
      db.collection("serviceDatas").add({
        // id: "4545455",
        service: sortBy,
        position: valueService,
        difference: valueService - sortedItems[0].data.position,

        createAt: Date.now(),
        // createAt: d,
      });
    } else {
      window.alert("Írjon be nagyobb értéket!");
    }

    setValueService("");
  }

  /*function deleteAllDatas() {
    const confirmed = window.confirm(
      "Are you sure you want to delete all items?"
    );

    if (confirmed) db.collection("serviceDatas").delete();
  }*/

  return (
    <div>
      <div>Víz / Gáz / Áram adminisztráció</div>
      <Sidebar
        sortBy={sortBy}
        setSortBy={setSortBy}
        //deleteAllDatas={deleteAllDatas}
      />
      <Mainpart
        sortedItems={sortedItems}
        sortBy={sortBy}
        valueService={valueService}
        setValueService={setValueService}
        addData={addData}
      />
    </div>
  );
}

export default App;
