import styles from "./Sidebar.module.css";

function Sidebar({ sortBy, setSortBy }) {
  const onOptionChange = (e) => {
    setSortBy(e.target.value);
  };

  return (
    <div className={styles.sidebar}>
      <p>Sidebar</p>
      <input
        type="radio"
        id="selectosszes"
        name="selectservice"
        value="osszes"
        checked={sortBy === "osszes"}
        onChange={onOptionChange}
      />
      <input
        type="radio"
        id="selectviz"
        name="selectservice"
        value="viz"
        checked={sortBy === "viz"}
        onChange={onOptionChange}
      />
      <input
        type="radio"
        id="selectgaz"
        name="selectservice"
        value="gaz"
        checked={sortBy === "gaz"}
        onChange={onOptionChange}
      />
      <input
        type="radio"
        id="selectaram"
        name="selectservice"
        value="aram"
        checked={sortBy === "aram"}
        onChange={onOptionChange}
      />
      <br></br>
    </div>
  );
}

export default Sidebar;
