import styles from "./Mainpart.module.css";
// import { Timestamp } from "firebase/firestore";

function Mainpart({
  sortedItems,
  sortBy,
  valueService,
  setValueService,
  addData,
}) {
  const formatDate = (date) =>
    new Intl.DateTimeFormat("hu", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(new Date(date));

  return (
    <div>
      <p>Főoldal</p>
      {sortBy !== "osszes" && (
        <div>
          <div>{sortBy}</div>
          <br></br>
          <input
            type="number"
            placeholder="Érték"
            value={valueService}
            onChange={(e) => setValueService(e.target.value)}
          />
          <button onClick={addData}>Hozzáad {sortBy}</button>
        </div>
      )}
      <br></br>

      <table>
        <tbody>
          {sortedItems?.map(({ id, data }) => (
            <tr key={id}>
              <td>{data.service}</td>
              <td>{data.position}</td>
              <td>({formatDate(data.createAt)})</td>
              {sortBy !== "osszes" && <td>{data.difference}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Mainpart;
