import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA9uG54LQ3l0o3mxlb4U3RQ2p6zyIvGI5o",
  authDomain: "vizgazaram-react.firebaseapp.com",
  projectId: "vizgazaram-react",
  storageBucket: "vizgazaram-react.appspot.com",
  messagingSenderId: "642177851351",
  appId: "1:642177851351:web:6c5ab984359fd3650eb592",
  measurementId: "G-V9EXLZ7X9F",
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
// const auth = firebase.auth();

export default db;
